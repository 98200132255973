import React, { useState, useEffect } from "react";
import { fetchGalleryImageData } from "../../../api/load-gallery-image.ts";
import { Loading } from "../../common/path.ts";
import { useInView } from "react-intersection-observer";
import { IMAGE_URL } from "../../../constant/AppConstants.ts";

export const GalleryPage = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageCount, setImageCount] = useState(10);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const dataSet = await fetchGalleryImageData();

        const importedImages:any = await Promise.all(
          dataSet.galleryImage.slice(0, imageCount).map(async (index) => {
            try {
              const imgModule = await (`${IMAGE_URL}/${index}`);
              return imgModule;
            } catch (error) {
              console.error("Error loading image:", error);
              return null;
            }
          })
        );

        

        setGalleryImages(importedImages.filter((image) => image !== null));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [imageCount]);

  useEffect(() => {
    if (inView) {
      setImageCount((prevCount) => prevCount + 10);
    }
  }, [inView]);

  return (
    <>

        <section>
          <div className="container-fluid px-md-0">
            <div className="row no-gutters">
              {galleryImages.map((imageUrl, index) => (
                <div
                  key={index}
                  className="col-md-4"
                  data-aos="fade-down"
                  data-aos-delay="300"
                  style={{ padding: "0px" }}
                >
                  <div className="d-flex align-items-end">
                    <img
                      className="img-fluid"
                      src={imageUrl}
                      alt={`Gallery Image ${index}`}
                    />
                  </div>
                </div>
              ))}
              <div ref={ref} style={{ height: "1px", width: "100%" }}></div>
            </div>
          </div>
        </section>
      
    </>
  );
};
