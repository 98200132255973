import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './components/pages/index.tsx';
import './App.css'
import "jquery"
import 'popper.js';

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
);
