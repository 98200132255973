import React, { useState } from "react";
import { IMAGE_URL } from "../../../../constant/AppConstants.ts";
import validator from "validator";
import { checkAdminAuth } from "../../../../api/path.ts";
import { Loading } from "../../../common/path.ts";
import { useNavigate } from "react-router-dom";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNotAuthenticated, setIsNotAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (password.length > 5 && validator.isEmail(email)) {
      try {
        setLoading(true);
        const dataSet = await checkAdminAuth(email, password);
        console.log(dataSet);

        if (dataSet.isAuthSuccess) {
          setIsNotAuthenticated(false);
          sessionStorage.setItem("user", JSON.stringify(dataSet.userData));

          if (dataSet.userData.role == "admin") {
            navigate("/admin/dashboard");
          } else {
            navigate("/admin/ticket-issuing");
          }
        } else {
          setIsNotAuthenticated(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    e.preventDefault();

    if (!validator.isEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }

    if (password.length < 5) {
      setPasswordError("Password must be at least 5 characters");
    } else {
      setPasswordError("");
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <section className="d-flex flex-column justify-content-start align-items-center vh-100">
        <img
          src={`${IMAGE_URL}/logo.png`}
          style={{ width: "15%" }}
          className="mt-3"
          alt=""
        />
        <div className="col-lg-4 col-md-6 col-sm-8 col-10">
          <p className="text-danger">
            {isNotAuthenticated ? "Invalied user name or password" : ""}
          </p>
          <div className="form-outline mb-1">
            <input
              type="email"
              className={`form-control ${emailError ? "is-invalid" : ""}`}
              value={email}
              onChange={handleEmailChange}
            />
            <label className="form-label">Email address</label>
            {emailError && <div className="invalid-feedback">{emailError}</div>}
          </div>

          <div className="form-outline mb-4">
            <input
              type="password"
              className={`form-control ${passwordError ? "is-invalid" : ""}`}
              value={password}
              onChange={handlePasswordChange}
            />
            <label className="form-label">Password</label>
            {passwordError && (
              <div className="invalid-feedback">{passwordError}</div>
            )}
          </div>

          <button
            type="button"
            className="btn btn-primary btn-block mb-1"
            onClick={handleSubmit}
          >
            Sign in
          </button>
        </div>
      </section>
    </>
  );
};
