import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAdminDashBoardData } from "../../../../api/path.ts";
import { Loading } from "../../../common/path.ts";

export const Ticketissuing = () => {
  //auth manage
  const navigate = useNavigate();
  const userString = sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : { role: "" };
  const role = user.role;

  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState<any>();
  const [qrcode, setQrCode] = useState<any>('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const dataSet = await fetchAdminDashBoardData();
      setDashboard(dataSet);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userString || role !== "admin") {
      navigate("/admin/sign-in");
    }
    fetchData();
  }, []);

  const handleTicketIssuance = (id: any) => {
    console.log(id);
  };

  console.log(qrcode);
  
  return (
    <>
      {loading ? (
        <Loading />
      ) : dashboard ? (
        <div>
          <div>
            <div className="mb-1 w-75 ms-5">
              <label className="form-label">Scan QR</label>
              <input
                type="text"
                className={"form-control border-1 border-light"}
                value={qrcode}
                onChange={(e) => {
                    setQrCode(e.target.value)
                }}
              />
            </div>
          </div>
          <section className="d-flex flex-column flex-md-row mb-5 mt-3">
            <div className="col-12">
              <table className="table align-middle mb-0 bg-white overflow-x-hidden">
                <thead className="bg-light">
                  <tr>
                    <th>Name</th>
                    <th>Order Id</th>
                    <th>Tickets(s)</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboard.customerData.map((customer, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="fw-bold mb-0">{customer.name}</p>
                            <p className="text-muted mb-0">{customer.email}</p>
                            <p className="text-muted mb-0">
                              {customer.contact}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="fw-bold text-wrap">{customer.order_id}</p>
                      </td>
                      <td>
                        {customer.tickets.map((ticket, index) => (
                          <p key={index} className="fw-normal mb-1">
                            {ticket.name} * {ticket.qty}
                          </p>
                        ))}
                      </td>
                      <td>{customer.order_date}</td>
                      <td>
                        {customer.status === "0" ? (
                          <>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                handleTicketIssuance(customer.order_id);
                              }}
                            >
                              Ticket Issuance
                            </button>
                          </>
                        ) : (
                          <>
                            <p className="badge badge-pill badge-success p-2">
                              Tickets have been issued
                            </p>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      ) : null}
    </>
  );
};
