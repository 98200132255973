import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { CURRENCY } from '../../../constant/AppConstants.ts';

const ImageModal = ({onOpen,imageUrl, onClose , name , price , note}) => {
  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
       <Modal show={onOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-dark fw-bold'>{name} Tickets - {CURRENCY}{price}.00</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <p>{note}</p>

          <img src={imageUrl} className='w-100' alt="" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageModal;
