import React, { useEffect, useState } from "react";
import ImageModal from "../../common/image-modal/ImageMdal.tsx";
import { Link, useLocation } from "react-router-dom";
import { CartCheckFill, DashCircle, PlusCircle } from "react-bootstrap-icons";
import { fetctTicketData } from "../../../api/path.ts";
import {
  COUNTRY_CODE,
  CURRENCY,
  IMAGE_URL,
  PUBLIC_API,
  SERVER_LINK,
} from "../../../constant/AppConstants.ts";
import { Loading } from "../../common/path.ts";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.tsx";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(PUBLIC_API);

export const BooknowPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const pid = searchParams.get("id");
  const [eventData, setEventData] = useState<any>();
  const [ticketQuantities, setTicketQuantities] = useState<{
    [key: string]: number;
  }>({});
  const [totalValue, setTotalValue] = useState(0);
  const [isBlockAddCart, setIsBlockAddCart] = useState(false);

  // Get existing cart items from session storage or initialize an empty array
  const existingCartItems: any[] = JSON.parse(
    sessionStorage.getItem("cartItems") || "[]"
  );

  //payment
  const [agreeBtn, setAgreeBtn] = useState(false);
  const [customerName, setCustomerName] = useState<any>("");
  const [customerEmail, setCustomerEmail] = useState<any>("");
  const [customerTel, setCustomerTel] = useState<any>("");
  const [customerAddr, setCustomerAddr] = useState<any>("");

  const userData = {
    customerName,
    customerEmail,
    customerTel,
    customerAddr,
  };

  // Data load
  const fetchData = async () => {
    try {
      if (id) {
        setLoading(true);
        const dataSet = await fetctTicketData(parseInt(id));
        setEventData(dataSet);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location.search]);

  // Calculate total value whenever cart items change
  useEffect(() => {
    const totalValue = existingCartItems.reduce(
      (acc, item) => acc + item.qty * item.price,
      0
    );

    if (isNaN(totalValue)) {
      sessionStorage.removeItem("cartItems");
    }
    setTotalValue(totalValue);
  }, [existingCartItems]);

  const handleImageClick = (imageUrl, name, price, note) => {
    setSelectedImage(imageUrl);
    setSelectedName(name);
    setSelectedPrice(price);
    setSelectedNote(note);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
    setSelectedName(null);
    setSelectedPrice(null);
  };

  // Cart qty handling
  const handleIncrease = (id: string, ticketLimit: number) => {
    if (
      ticketLimit > 0 &&
      (ticketLimit > ticketQuantities[id] || !ticketQuantities[id])
    ) {
      setTicketQuantities((prevQuantities) => ({
        ...prevQuantities,
        [id]: (prevQuantities[id] || 0) + 1,
      }));
    }
  };

  const handleDecrease = (id: string) => {
    if (
      (ticketQuantities[id] && ticketQuantities[id] > 0) ||
      (existingCartItems.find((item) => item.id === id)?.qty || 0) > 0
    ) {
      if (ticketQuantities[id] === 1) {
        // Remove the item from the cart if the quantity is 1
        setTicketQuantities((prevQuantities) => {
          const { [id]: _, ...rest } = prevQuantities;
          return rest;
        });
        // Remove the item from existingCartItems
        sessionStorage.setItem(
          "cartItems",
          JSON.stringify(existingCartItems.filter((item) => item.id !== id))
        );
      } else {
        // Decrease the quantity by 1
        setTicketQuantities((prevQuantities) => ({
          ...prevQuantities,
          [id]: prevQuantities[id] - 1,
        }));
        // Update the quantity in existingCartItems
        const existingItemIndex = existingCartItems.findIndex(
          (item) => item.id === id
        );
        if (existingItemIndex !== -1) {
          existingCartItems[existingItemIndex].qty -= 1;
          sessionStorage.setItem(
            "cartItems",
            JSON.stringify(existingCartItems)
          );
        }
      }
    }
  };


  const isValidJapanesePhoneNumber = (phoneNumber:any) => {
    const regex = new RegExp(`^\\${COUNTRY_CODE}`);
  return regex.test(phoneNumber);
  };

  const handleCartItem = (id: any, qty: any, price: any) => {
    const existingItemIndex = existingCartItems.findIndex(
      (item) => item.id === id
    );
    let total = 0;

    if (existingItemIndex !== -1) {
      existingCartItems[existingItemIndex].qty = qty;
    } else {
      existingCartItems.push({ id, qty, price, pid });
    }

    // Calculate total value
    existingCartItems.forEach((item) => {
      total += item.qty * item.price;
    });

    sessionStorage.setItem("cartItems", JSON.stringify(existingCartItems));
    setTotalValue(total);
  };

  //payment gatway
  const [clientSecret, setClientSecret] = useState<any>();

  const handlePayment = () => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${SERVER_LINK}/create.php`, {
      method: "POST",
      body: JSON.stringify({
        items: existingCartItems,
        total: totalValue,
        userData: userData,
        eventId: id,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          errorNotify();
          throw new Error("Error fetching data");
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        console.log(data);
      })
      .catch((error) => {
        errorNotify();
      });
  };

  const appearance = {
    theme: "night",
  };
  const options: any = {
    clientSecret,
    appearance,
  };

  const proceedToPayNotify = () =>
    toast.success("Proceeding...", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const errorNotify = () =>
    toast.success("Somthing went wrong.please try againg later...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const addToCartNotify = () =>
    toast.success("Ticket Added.", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  useEffect(() => {
    sessionStorage.setItem("userData", JSON.stringify(userData));
  }, [handlePayment]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {eventData && eventData.isBookingAvailable ? (
            <>
              <section className="row container mx-0">
                <div className="col-12 col-md-6 mb-5">
                  <h2
                    className="text-center text-uppercase text-decoration-none fw-bold py-2 mx-2 rounded mt-4 bg-danger d-flex justify-content-center align-items-center"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <CartCheckFill/> <span className="ms-3">Ticket Cart</span>
                  </h2>

                  <div
                    className="mx-2 "
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    {/* {cart card} */}
                    {eventData.ticket.map((data, key) => (
                      <div
                        className="col-12 d-flex flex-column flex-md-row border border-1 border-light rounded my-2 py-3 px-1"
                        key={key}
                      >
                        <div className=" col-12 col-md-5">
                          <img
                            src={`${IMAGE_URL}/${data.image}`}
                            className="w-100"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleImageClick(
                                `${IMAGE_URL}/${data.image}`,
                                `${data.name}`,
                                `${data.price}`,
                                `${data.note}`
                              )
                            }
                          />
                        </div>
                        <div className="col-12 col-md-7 text-center">
                          <div className="col-12">
                            <p className="text-light fs-6">
                              {data.name} : {CURRENCY}
                              {data.price}
                            </p>
                            <div>
                              <div>
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={() => handleDecrease(data.id)}
                                >
                                  <span className="fw-bolder fs-6">
                                    <DashCircle />
                                  </span>
                                </button>
                                <span className="px-3">
                                  {ticketQuantities[data.id] ||
                                    existingCartItems.find(
                                      (item) => item.id === data.id
                                    )?.qty ||
                                    0}
                                </span>
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={() => {
                                    handleIncrease(data.id, data.ticketcount);
                                  }}
                                >
                                  <span className="fw-bolder fs-6">
                                    <PlusCircle />
                                  </span>
                                </button>
                              </div>

                              <div className="mx-auto text-warning mt-2">
                                <p
                                  className="text-center"
                                  style={{ fontSize: "0.75rem" }}
                                >
                                  We have {data.ticketcount} ticket available in
                                  the {data.name} category.
                                </p>
                              </div>

                              <div>
                                {(ticketQuantities[data.id] &&
                                  ticketQuantities[data.id] > 0) ||
                                existingCartItems.find(
                                  (item) => item.id === data.id
                                )?.qty > 0 ? (
                                  <p className="text-light fs-6">
                                    Total value : {CURRENCY}:
                                    {data.price * ticketQuantities[data.id] ||
                                      existingCartItems.find(
                                        (item) => item.id === data.id
                                      )?.price *
                                        existingCartItems.find(
                                          (item) => item.id === data.id
                                        )?.qty ||
                                      0}
                                  </p>
                                ) : (
                                  ""
                                )}

                                {((ticketQuantities[data.id] &&
                                  ticketQuantities[data.id] > 0 &&
                                  !isBlockAddCart) ||
                                  existingCartItems.find(
                                    (item) => item.id === data.id
                                  )?.qty > 0) &&
                                !isBlockAddCart ? (
                                  <button
                                    className="btn text-light py-1 rounded-pill btn-danger"
                                    onClick={() => {
                                      handleCartItem(
                                        data.id,
                                        ticketQuantities[data.id],
                                        data.price
                                      );
                                      addToCartNotify();
                                    }}
                                  >
                                    {" "}
                                    <CartCheckFill className="me-1 " /> Add to
                                    cart
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="col-12 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <h2 className="text-center text-uppercase mt-4 mb-4">
                    {totalValue != 0 && !isNaN(totalValue)
                      ? `Total Price - ${CURRENCY}${totalValue}`
                      : ""}
                  </h2>

                  {clientSecret ? (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm />
                    </Elements>
                  ) : (
                    <div className="border border-1 border-light rounded px-3 pb-5 pt-3">
                      {/* data collect form */}
                      <div>
                        <div className="mb-3">
                          <label className="form-label">
                            Name <span className="text-danger fs-5">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            required
                            placeholder="Enter Your Name Here"

                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Email <span className="text-danger fs-5">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={customerEmail}
                            onChange={(e) => setCustomerEmail(e.target.value)}
                            required
                            placeholder="Enter Your Email Here"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Contact Number{" "}
                            <span className="text-danger fs-5">*</span>
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            value={customerTel}
                            onChange={(e) => setCustomerTel(e.target.value)}
                            required
                            placeholder="+819012345678"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Address <span className="text-danger fs-5">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerAddr}
                            onChange={(e) => setCustomerAddr(e.target.value)}
                            required
                            placeholder="Enter Your Address Here"

                          />
                        </div>
                      </div>

                      {/* proceed to pay */}
                      <div className="text-center">
                        <div className="form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="agree"
                            checked={agreeBtn}
                            onClick={() => {
                              setAgreeBtn(true);
                            }}
                          />
                          <label className="form-check-label">
                            I agree to the terms and conditions
                          </label>
                        </div>
                        <small className="text-muted">
                          Please read our{" "}
                          <Link to={"../terms-and-condition"}>
                            terms and conditions
                          </Link>{" "}
                          before agreeing.
                        </small>
                      </div>

                      <button
                        type="button"
                        className="btn btn-danger w-100"
                        disabled={
                          !agreeBtn ||
                          !customerName ||
                          !customerEmail ||
                          !customerTel ||
                          !customerAddr ||
                          totalValue <= 0
                        }
                        onClick={() => {
                          handlePayment();
                          proceedToPayNotify();
                          setIsBlockAddCart(true);
                        }}
                      >
                        Proceed to pay
                      </button>
                    </div>
                  )}
                </div>
              </section>

              {/* payment  */}
              <ToastContainer />

              {/* Modal */}
              {showModal && (
                <ImageModal
                  onOpen={showModal}
                  imageUrl={selectedImage}
                  onClose={handleCloseModal}
                  note={selectedNote}
                  name={selectedName}
                  price={selectedPrice}
                />
              )}
            </>
          ) : (
            <>
              <div className="text-center mt-5 d-flex align-items-center">
                <h1>Ticket booking is not available at that moment</h1>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
