import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAdminDashBoardData } from "../../../../api/path.ts";
import { Loading } from "../../../common/path.ts";

export const Dashboard = () => {
  //auth manage
  const navigate = useNavigate();
  const userString = sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : { role: "" };
  const role = user.role;

  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState<any>();

  const fetchData = async () => {
    try {
      setLoading(true);
      const dataSet = await fetchAdminDashBoardData();
      setDashboard(dataSet);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userString || role !== "admin") {
      navigate("/admin/sign-in");
    }
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : dashboard ? (
        <div>
          <section className="d-flex flex-wrap justify-content-between col-12 container">
            {dashboard.dashboardtile?.map((item, index) => (
              <div
                key={index}
                className="col-12 col-md-6 col-lg-4 border border-2 border-light text-center py-4  rounded mt-1"
              >
                <h5>{item.name}</h5>
                <p>{item.value}</p>
              </div>
            ))}
          </section>
        </div>
      ) : null}
    </>
  );
};
