import config from './config';

export const WEBNAME = config.webName;

export const FACEBOOK = config.facebook;
export const INSTERGRAM = config.instergram;
export const YOUTUBE = config.youtube;
export const TIKTOK = config.tiktok;

export const ADDRESS = config.address;
export const MOBILE = config.mobile;
export const EMAIL = config.email;
export const WHATSAPP = config.whatsapp;
export const GOOGLEMAP = config.googleMap;

export const WEB_LINK = config.webLink;
export const SERVER_LINK =  config.server;
export const AUTH_REQ =  config.authReq;
export const IMAGE_URL = config.imagepath;
export const CURRENCY = config.currency;

export const PUBLIC_API = config.publickAPI;
export const COUNTRY_CODE = config.countryCode;

export const DEVS_SITE = config.devsSite;