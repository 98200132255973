import React, { useState } from "react";
import { Link } from "react-scroll";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const AdminNav = () => {
  const location = useLocation();

  const handleClick = (path: string) => {
    window.location.href = `/admin/${path}`;
  };

  const userString = sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : { role: "" };
  const role = user.role;
  
  if (!userString || location.pathname === "/admin/sign-in") {
    return null;
  }


  return (
    <>
      <Navbar expand="md" className="bg-body-dark">
        <Container fluid>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            aria-label="Toggle navigation"
            className="btn-light my-2"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 "
              style={{ maxHeight: "150px" }}
              navbarScroll
            >
              {role == "admin" && (
                <Nav.Link>
                  <Link
                    className={`nav-link text-light`}
                    onClick={() => handleClick("dashboard")}
                  >
                    Dashboard
                  </Link>
                </Nav.Link>
              )}

              {role == "admin" && (
                <Nav.Link>
                  <Link
                    className={`nav-link text-light`}
                    onClick={() => handleClick("add-event")}
                  >
                    Add Event Details
                  </Link>
                </Nav.Link>
              )}

              <Nav.Link>
                <Link
                  className={`nav-link text-light`}
                  onClick={() => handleClick("ticket")}
                >
                  Ticket Issuing
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
