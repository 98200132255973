import { SERVER_LINK } from "../constant/AppConstants.ts";

export const insertEventData = async (
  formData: any
) => {
  try {

    const response = await fetch(`${SERVER_LINK}/insert-event-data.php`, {
        method: 'POST',
        //mode: 'no-cors',
        // headers: {
        //   'Content-Type': 'application/json'
        // },
        body: formData
    });

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};
