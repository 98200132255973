import React from "react";
import {
  DEVS_SITE,
  FACEBOOK,
  INSTERGRAM,
  YOUTUBE,
} from "../../../constant/AppConstants.ts";
import { Link, useLocation } from "react-router-dom";

export const FooterBar = () => {
  const currentYear = new Date().getFullYear();
  const { pathname } = useLocation();

  return pathname.startsWith("/admin") ? (
    <></>
  ) : (
    <section className="footer-section">
      <div className="container text-center">
        <div className="row">
          <div className="col-md-12 text-center">
            <ul className=" list-style-none p-0">
              <li className="">
                <Link to={YOUTUBE}>
                  {" "}
                  <span className="icon-youtube"></span>
                </Link>
              </li>
              <li className="">
                <Link to={FACEBOOK}>
                  <span className="icon-facebook"></span>
                </Link>
              </li>
              <li className="">
                <Link to={INSTERGRAM}>
                  {" "}
                  <span className="icon-instagram"></span>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <p>
              <Link to="/terms-and-condition">
                Money Return Policy and Terms & Conditions
              </Link>
              {""}
            </p>
          </div>

          <p>
            Copyright &copy; {currentYear} |All rights reserved by{" "}
            <Link to={`${DEVS_SITE}`}>TaproDev</Link>
          </p>
        </div>
      </div>

      <div></div>
    </section>
  );
};
