import React, { useEffect, useState } from "react";

import { Element } from "react-scroll";
import { fetchHomePageData } from "../../../../api/path.ts";
import { Loading } from "../../../common/path.ts";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../../../constant/AppConstants.ts";

export const NextEvent = () => {
  const [loading, setLoading] = useState(false);
  const [nextEventData, setNextEventData] = useState<any>();
  const [mainImageSrc, setMainImageSrc] = useState<any>("");
  const [subImage1Src, setsubImage1Src] = useState<any>("");
  const [subImage2Src, setsubImage2Src] = useState<any>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const dataSet = await fetchHomePageData();
        setNextEventData(dataSet.nextEventData);

        const imageModuleMain =
          await `${IMAGE_URL}/${dataSet.nextEventData.mainImage}`;
        setMainImageSrc(imageModuleMain);

        const imageModulesub1 =
          await `${IMAGE_URL}/${dataSet.nextEventData.subImage1}`;
        setsubImage1Src(imageModulesub1);

        const imageModulesub2 =
          await `${IMAGE_URL}/${dataSet.nextEventData.subImage2}`;
        setsubImage2Src(imageModulesub2);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : nextEventData ? (
        <Element name="nextEvent" className="element">
          <section className="img my-5" id="nextEvent">
            <div className="container">
              <div className="row d-flex ">
                <div
                  className="col-md-6 col-lg-6 d-flex"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="img-about section-2-bg-img img d-flex align-items-stretch">
                    <div className="overlay"></div>
                    <div className="img img-video d-flex align-self-stretch align-items-center ">
                      <img src={mainImageSrc} className="w-100" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-6 pl-md-5"
                  data-aos="fade-down"
                  data-aos-delay="300"
                >
                  <div className="heading-section">
                    <h2
                      className="mb-4 text-uppercase"
                      data-aos="fade-down"
                      data-aos-delay="400"
                    >
                      {nextEventData.title}
                    </h2>
                    <p data-aos="fade-down" data-aos-delay="500">
                      {nextEventData.subTitle}
                    </p>

                    <div className="counter-wrap d-flex mt-md-4">
                      {/* <div className="text">
                        <p
                          className="mb-2 mt-4 fs-4"
                          data-aos="fade-down"
                          data-aos-delay="650"
                        >
                          <span className="number">
                            {" "}
                            {nextEventData.ticketCount}
                          </span>
                          <span className="ms-3">Tickets Sold</span>
                        </p>
                      </div> */}
                    </div>
                    <blockquote className="blockquote mt-1">
                      <p className="mb-2">EVENT DATE: {nextEventData.date}</p>
                      <Link
                        to={`/next-event?id=${nextEventData.id}`}
                        className="btn btn-sm btn-light rounded-5 font-weight-bold col-8 col-lg-5 mb-5float-left"
                      >
                        More About Event
                      </Link>
                    </blockquote>
                    <div className="d-flex w-100">
                      <div
                        className="img img-about-2 mr-2"
                        data-aos="fade-down"
                        data-aos-delay="700"
                      >
                        <img src={subImage1Src} className="w-100" alt="" />
                      </div>
                      <div
                        className="img img-about-2 ml-2"
                        data-aos="fade-down"
                        data-aos-delay="750"
                      >
                        <img src={subImage2Src} className="w-100" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Element>
      ) : null}
    </>
  );
};
