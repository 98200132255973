import React from "react";
import { EMAIL, MOBILE } from "../../../constant/AppConstants.ts";
import "../../../App.css";

export const TermsAndCondition = () => {
  return (
    <div className="container">
      <div className="col-11 py-5 px-0 mx-auto">
        <h1 className="fs-1 mb-3 fw-bolder">TERMS AND CONDITION</h1>

        <p>
          <ul>
            <li>Business Name: MaxWin</li>
            <li>Business Address: Ibaragiken Chikuseishi Nishiyagai 3651 Amagai 1 - 2</li>
            <li>Contact Information: +818072411994 ,maxwinevents@gmail.com</li>
            <li>Registration Number: 0500-03-003200</li>
          </ul>
        </p>

        <p>
          <strong>
            <span className="term-header fw-bold">
              1. Ticket Purchase Agreement:
            </span>
          </strong>
        </p>
        <p>
          <span className="term-header ">
            By purchasing tickets through our website [www.maxwin.events], you
            agree to abide by the terms and conditions outlined herein. This
            agreement is between [MaxWin], hereafter referred to as "the
            Company," and the ticket purchaser, hereafter referred to as "the
            Customer."
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">2. Refund Policy:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            All ticket sales are final, and the Company does not offer refunds
            under any circumstances. This includes, but is not limited to,
            changes in event details, performer cancellations, or personal
            reasons preventing attendance.
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">
              3. Event Changes or Cancellations:
            </span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            While the Company endeavors to provide accurate event information,
            circumstances beyond our control may require changes to the event
            schedule, venue, or performers. In the event of cancellations or
            significant changes, the Company will make reasonable efforts to
            notify ticket holders via the contact information provided during
            the purchase.
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">4. Ticket Transfers:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            Tickets purchased through our website are transferable to another
            individual. The original ticket holder must inform the Company of
            the transfer, providing the new attendee's name and contact
            information.
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">5. Ticket Resale:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            Reselling tickets for a price higher than the face value is strictly
            prohibited. The Company reserves the right to void any tickets found
            to be resold in violation of this policy.
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">
              6. Lost or Stolen Tickets:
            </span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            The Company is not responsible for lost or stolen tickets. It is the
            Customer's responsibility to keep tickets secure.
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">7. Event Entry:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            To gain entry to the event, customers must present a valid ticket
            along with a form of identification. The Company reserves the right
            to deny entry to individuals with invalid or duplicated tickets.
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">8. Event Policies:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            Customers are expected to adhere to the policies and guidelines set
            forth by the event venue, including any security and safety
            measures. <br />A ticket is mandatory for entry to the show
          </span>
        </p>
        <p>
          <strong>
            <span className="term-header fw-bold">9. Governing Law:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            This agreement shall be governed by and construed in accordance with
            the laws of [MaxWin].
          </span>
        </p>

        <p>
          <strong>
            <span className="term-header fw-bold">10. Prohibited Items:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            Carrying alcohol and cigarettes is strictly prohibited.
          </span>
        </p>

        <p>
          <strong>
            <span className="term-header fw-bold">10. Timeliness:</span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            Please arrive early to ensure the show starts on time. Sit in your
            designated ticket zone.
          </span>
        </p>

        <p>
          <strong>
            <span className="term-header fw-bold">
              11. Contact Information:
            </span>
          </strong>
        </p>
        <p>
          <span className="term-header">
            For any questions or concerns regarding this Money Return Policy and
            Terms & Conditions, please contact us at [{MOBILE} ,{EMAIL}].
          </span>
        </p>
      </div>

      <div className="col-11 py-5 px-0 mx-auto">
        <h1 className="fs-1 mb-3 fw-bolder">COMMERCE DISCLOSURE</h1>
        <table className="table text-light">
          <tr>
            <td>Legal Name</td>
            <td>
              K. P. AGENCIES (MaxWin is a brand name for event organization)
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>Ibaragiken Chikuseishi Nishiyagai 3651 Amagai 1 - 2</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              +818072411994 (Hours: 8:00 - 18:00, excluding weekends and
              holidays)
            </td>
          </tr>
          <tr>
            <td>Email Address</td>
            <td>maxwinevents@gmail.com</td>
          </tr>
          <tr>
            <td>Head of Operations</td>
            <td>N.M.C.S Chandrasiri</td>
          </tr>
          <tr>
            <td>Additional Fees</td>
            <td>No additional fees</td>
          </tr>
          <tr>
            <td>Exchanges & Returns Policy</td>
            <td>See terms and conditions</td>
          </tr>
          <tr>
            <td>Delivery Times</td>
            <td>
              Customers are required to collect tickets at the event entrance
            </td>
          </tr>
          <tr>
            <td>Accepted Payment Methods</td>
            <td>Accept Visa, Mastercard and American Express payments</td>
          </tr>
          <tr>
            <td>Payment Period</td>
            <td>Credit card payments are processed immediately.</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>
              Need to pay in ¥, and all prices are displayed on the tickets and
              ticket collection page.
            </td>
          </tr>{" "}
        </table>
      </div>
    </div>
  );
};
