import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import { fetchHomePageData } from "../../../../api/path.ts";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../../../constant/AppConstants.ts";

export const Gallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const dataSet = await fetchHomePageData();
  
        const importedImages:any = await Promise.all(
          dataSet.galleryImage.map(async (index) => {
            try {
              const imgModule = await (`${IMAGE_URL}/${index}`);              
              return imgModule;
            } catch (error) {
              console.error("Error loading image:", error);
              return null;
            }
          })
        );
  
        // Filter out any null values that occurred due to errors
        setGalleryImages(importedImages.filter((image) => image !== null));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  return (
    <Element name="gallery" className="element" id="gallery">
      <section className="mt-5">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-md-6 heading-section text-start pb-5">
              <h2
                className="mb-4 d-flex align-items-baseline"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                <span className="me-3">Our Gallery</span>{" "}
                <Link to={'gallery'}><ArrowRightCircleFill /></Link>                  
              </h2>
              {/* <p data-aos="fade-down" data-aos-delay="400">
                A small river named Duden flows by their place and supplies it
                with the necessary regelialia. It is a paradisematic country.
              </p> */}
            </div>
          </div>
        </div>

        <div className="container-fluid px-md-0">
          <div className="row no-gutters">
            {galleryImages.map((imageUrl, index) => (
              <div
                key={index}
                className="col-md-4"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                <div
                  className="model img d-flex align-items-end"
                  style={{ backgroundImage: `url(${imageUrl})`}}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Element>
  );
};
