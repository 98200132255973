import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BooknowPage,
  PaymentSuccess,
  GalleryPage,
  HomePage,
  NextEventPage,
  TermsAndCondition,
} from "./path.ts";
import { FooterBar, NavBar, ScrollUpButton } from "../common/path.ts";

import AOS from "aos";
import "aos/dist/aos.css";

// CSS
import "../../css/open-iconic-bootstrap.min.css";
import "../../css/animate.css";
import "../../css/owl.theme.default.min.css";
import "../../css/magnific-popup.css";
import "../../css/aos.css";
import "../../css/ionicons.min.css";
import "../../css/bootstrap-datepicker.css";
import "../../css/jquery.timepicker.css";
import "../../css/flaticon.css";
import "../../css/icomoon.css";
import "../../css/style.css";
import { AddEvent, Dashboard, SignIn, Ticketissuing } from "./admin/path.ts";

const AppRouter = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once:true
    });
  }, []);

  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/next-event" element={<NextEventPage />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/ticket-booking" element={<BooknowPage />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />

          <Route path="/admin/sign-in" element={<SignIn />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/add-event" element={<AddEvent />} />
          <Route path="/admin/ticket" element={<Ticketissuing />} />
        </Routes>
        <ScrollUpButton />
        <FooterBar />
      </Router>
    </>
  );
};

export default AppRouter;
