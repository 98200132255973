import React, { useState } from "react";
import { Link } from "react-scroll";
import { Container, Nav, Navbar } from "react-bootstrap";

export const WebNav = () => {
  const [activeLink, setActiveLink] = useState("home");

  const handleClick = (path: string) => {
    setActiveLink(path);
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    } else {
      const element = document.getElementById(path);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <Navbar expand="md" className="bg-body-dark">
        <Container fluid>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            aria-label="Toggle navigation"
            className="btn-light my-2"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 "
              style={{ maxHeight: "150px" }}
              navbarScroll
            >
              <Nav.Link>
                <Link
                  className={`nav-link text-light  dot ${
                    activeLink === "home" ? "active" : ""
                  }`}
                  onClick={() => handleClick("home")}
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  className={`nav-link text-light dot ${
                    activeLink === "nextEvent" ? "active" : ""
                  }`}
                  onClick={() => handleClick("nextEvent")}
                  to="nextEvent"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Next Event
                </Link>
              </Nav.Link>
              {/* <Nav.Link>
                <Link
                  className={`nav-link text-light dot ${
                    activeLink === "upcommingEvent" ? "active" : ""
                  }`}
                  onClick={() => handleClick("upcommingEvent")}
                  to="upcommingEvent"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Upcoming Event
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  className={`nav-link text-light dot ${
                    activeLink === "PastEvent" ? "active" : ""
                  }`}
                  onClick={() => handleClick("PastEvent")}
                  to="PastEvent"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Past Event
                </Link>
              </Nav.Link> */}
              <Nav.Link>
                <Link
                  className={`nav-link text-light dot ${
                    activeLink === "gallery" ? "active" : ""
                  }`}
                  onClick={() => handleClick("gallery")}
                  to="gallery"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Our Gallery
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  className={`nav-link text-light dot ${
                    activeLink === "aboutus" ? "active" : ""
                  }`}
                  onClick={() => handleClick("aboutus")}
                  to="aboutus"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  About us
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  className={`nav-link text-light dot ${
                    activeLink === "contactus" ? "active" : ""
                  }`}
                  onClick={() => handleClick("contactus")}
                  to="contactus"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Contact us
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
