import { AUTH_REQ, SERVER_LINK } from "../constant/AppConstants.ts";

export const fetctTicketData = async (id:any) => {
  try {
    const response = await fetch(`${SERVER_LINK}/load-cart-ticket.php?id=${id}&&auth=${AUTH_REQ}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};
