import { AUTH_REQ, SERVER_LINK } from "../constant/AppConstants.ts";

export const fetchAdminDashBoardData = async () => {
  try {
    const form = new FormData();
    form.append("auth", AUTH_REQ);

    const response = await fetch(
      `${SERVER_LINK}/load-admin-dashboard.php`,
      {
        method: "POST",
        body: form, // Sending form data directly
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};
