import React from "react";
import {
  HeroSection,
  AboutSection,
  UpComming,
  Gallery,
  ContactUs,
  NextEvent,
  PastEvent,
} from "./component/path.ts";

export const HomePage = () => {
  return (
    <>
      <HeroSection />
      <NextEvent />
      {/* <UpComming />
      <PastEvent /> */}
      <Gallery />
      <AboutSection />
      <ContactUs />
    </>
  );
};
