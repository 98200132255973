import { AUTH_REQ, SERVER_LINK } from "../constant/AppConstants.ts";

export const insertTicketOrderData = async (
  clientSecret: any,
  payment_intent:any,
  userData: any,
  ticketData: any
) => {
  try {
    const form = new FormData();
    form.append("auth", AUTH_REQ);
    form.append("clientSecret", clientSecret);
    form.append("payment_intent", payment_intent);
    form.append("userData", JSON.stringify(userData));
    form.append("ticketData", JSON.stringify(ticketData));

    const response = await fetch(`${SERVER_LINK}/insert-payment-data.php`, {
      method: "POST",
      body: form, // Sending form data directly
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};
