import { AUTH_REQ, SERVER_LINK } from "../constant/AppConstants.ts";

export const checkAdminAuth = async (
        userEmail:any,
        userPass:any
) => {
  try {
    const form = new FormData();
    form.append("auth", AUTH_REQ);
    form.append("userEmail", userEmail);
    form.append("userPass", userPass);

    const response = await fetch(`${SERVER_LINK}/check-admin-auth.php`, {
      method: "POST",
      body: form, // Sending form data directly
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};
