import React from "react";
import { Element } from "react-scroll";
import { IMAGE_URL } from "../../../../constant/AppConstants.ts";

export const AboutSection = () => {
  return (
    <Element name="aboutus" className="element">
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-10 heading-section col-md-10 col-12 pb-5">
                  <h2
                    className="mb-4"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    MAXWIN About Us..{" "}
                  </h2>
                  <p data-aos="fade-up" data-aos-delay="300">
                    {" "}
                    In the heart of Japan and Sri Lankan, Max Win International
                    CO.LTD emerges as a dynamic event management company,
                    bringing innovation, meticulous planning, and flawless
                    execution to a diverse array of events. Founded in 2023, our
                    company is set to become a trusted partner in crafting
                    unforgettable experiences for our clients and their
                    audiences.{" "}
                  </p>
                </div>
              </div>
              <div className="row col-12">
                <div
                  className="col-md-4 col-12"
                  data-aos="fade-down"
                  data-aos-delay="200"
                >
                  <div className="media block-6 services d-block">
                    <div className="icon">
                      <span className="icon-visibility mb-3"></span>
                    </div>
                    <div className="media-body">
                      <h3 className="heading mb-3">Maxwin Vision</h3>
                      <p>
                        Max Win's mission is to elevate ordinary events
                        extraordinary into experiences, customized to the unique
                        goals and visions of our clients. Committed to seamless,
                        delivering creative, and memorable events, we aim to
                        leave a lasting impression on every occasion.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-4 col-12"
                  data-aos="fade-down"
                  data-aos-delay="300"
                >
                  <div className="media block-6 services d-block ">
                    <div className="media-body">
                      <img
                        src={`${IMAGE_URL}/logo.png`}
                        alt="maxwin"
                        className="w-75 mx-auto d-flex "
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-4 col-12"
                  data-aos="fade-down"
                  data-aos-delay="200"
                >
                  <div className="media text-end block-6 services d-block">
                    <div className="icon">
                      <span className="icon-history mb-3"></span>{" "}
                    </div>
                    <div className="media-body">
                      <h3 className="heading mb-3">Our Expirience</h3>
                      <p style={{lineHeight:'1.4rem'}}>
                        At Max Win, we transform events into immersive
                        experiences that surpass expectations. With a focus on
                        creativity and meticulous planning, we tailor each event
                        to reflect the distinctive visions and goals of our
                        clients. Our commitment to excellence ensures seamless
                        execution, leaving a lasting impression and creating
                        unforgettable memories for every occasion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};
