import React from "react";
import { Element } from "react-scroll";
import {
  ADDRESS,
  EMAIL,
  GOOGLEMAP,
  MOBILE,
  WHATSAPP,
} from "../../../../constant/AppConstants.ts";
import { Link } from "react-router-dom";

export const ContactUs = () => {
  return (
    <Element name="contactus" className="element" id="contactus">
      <section className="contact-section mt-5">
        <div className="container">
          <div className="row justify-content-start mb-3 pb-3">
            <div className="col-md-6 heading-section text-start ">
              <h2 className="mb-2" data-aos="fade-up" data-aos-delay="300">
                Contact Us..
              </h2>
              <p data-aos="fade-up" data-aos-delay="400">
                We'd love to hear from you! If you have any questions or
                inquiries regarding our services, please don't hesitate to reach
                out to us.
              </p>
            </div>
          </div>

          <div className="row mb-5">
            <div
              className="col-md-3 d-flex"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              <div className="align-self-stretch box text-center p-4">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-map-signs"></span>
                </div>
                <div>
                  <h3 className="mb-4">Address</h3>
                  <p>{ADDRESS}</p>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 d-flex "
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className="align-self-stretch box text-center p-4">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-phone2"></span>
                </div>
                <div>
                  <h3 className="mb-4">Contact Number</h3>
                  <p>
                    <Link to={`${`tel:${MOBILE}`}`}>{MOBILE}</Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 d-flex "
              data-aos="fade-down"
              data-aos-delay="500"
            >
              <div className="align-self-stretch box text-center p-4">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-paper-plane"></span>
                </div>
                <div>
                  <h3 className="mb-4">Email Address</h3>
                  <p>
                    <Link to={`${`mailto:${EMAIL}`}`}>{EMAIL}</Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 d-flex "
              data-aos="fade-down"
              data-aos-delay="600"
            >
              <div className="align-self-stretch box text-center p-4">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-whatsapp"></span>
                </div>
                <div>
                  <h3 className="mb-4">WhatsApp</h3>
                  <p>
                    <Link to={`https://wa.me/${WHATSAPP}`}>{WHATSAPP}</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row block-12">
            <div className="col-12 d-flex align-items-stretch">
              <iframe
                className="w-100 mb-5"
                src={GOOGLEMAP}
                height="250"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};
